import {
    FILTER_TYPE_SELECT,
    PRODUCT_COUNT_SMALL_WIDTH,
    PRODUCT_COUNT_FULL_WIDTH,
    FILTER_TYPE_CHECKBOX,
    RECIPE_TYPES,
} from "../constant";
import { RECIPES_URL, getFetchRequest } from "../store/requests";
import { i18n } from '../i18n';
import { recipesService } from "./recipesService";
import { isSmallWebScreen, sortByDate } from "../components/helper";
import { productsStorage } from "./productsStorage";
import { productsService } from "./productsService";
import { recipeIngredientService } from "./recipeIngredientsService";
import {errorCallback, makeUrl} from "../util";
import {RECIPE_FILTER_GROUP_INGREDIENT, RECIPE_FILTER_GROUP_RECIPE_TYPE} from "./recipesUtil";

const createVariantsList = (productsList) => {
    let result = [];
    productsList.forEach(it => result.push({id: it.productCode, name: productsService.getLocalization(i18n.APP_LOCALE, it.productCode)}));
    return result;
};

const buildInitialFilters = () => {
    return RECIPE_TYPES.map(it => {
        return  {
            filterTitle: i18n.get(`recipe.scheme.recipeType.${it.toLowerCase()}`),
            filterName: `${RECIPE_FILTER_GROUP_RECIPE_TYPE}.${it.toLowerCase()}`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: RECIPE_FILTER_GROUP_RECIPE_TYPE,
        };
    });
};

const createListOfFilters = (productsList) => {
    return [ 
        {
            filterTitle: i18n.get('app.page.property.filters.ingredient'),
            filterName: RECIPE_FILTER_GROUP_INGREDIENT,
            filterType: FILTER_TYPE_SELECT,
            variants: createVariantsList(productsList),
            value: false,
            filterGroupName: RECIPE_FILTER_GROUP_INGREDIENT,
        },
    ];
};

const filterRecipesListBySearchValue = (recipesList, searchValue) => {
    return recipesList.filter(it => recipesService.getLocalization(i18n.APP_LOCALE, it.recipeType, true).toLowerCase().includes(searchValue));
};

const parseFilterValue = (filterValue) => {
    if (filterValue === "true") return true;
    if (filterValue === "false") return false;
    return filterValue;
};

const filterRecipe = (recipe, filtersList) => {
    let count = 0;
    for(let filter of filtersList) {
        if (filter.filterType === FILTER_TYPE_CHECKBOX) {
            const filterValue = parseFilterValue(filter.filterName.split('.')[1]);
            if (recipe[filter.filterGroupName] === filterValue) {
                count++;
            }
        } else {
            const currentProduct = productsStorage.getById(filter.id);
            if(currentProduct) {
                recipe.ingredients.find(it => it.ingredientParts.find(ingredient => ingredient.productCode === currentProduct.productCode)) ?
                    count++
                    :
                    null;
            } else {
                count++;
            }
        }
    }
    return count > 0;
};

export const recipesStorage = {
    recipesList: [],
    filtersList: [],
    searchValue: '',
    observersList: [],
    productsList: [],
    possibleFilters: [],
    page: 1,
    offset: 10,
    maxPages: 1,

    loadItems(callback, width) {
        try {
            getFetchRequest(makeUrl([RECIPES_URL]), (response) => {
                this.recipesList = response.sort(sortByDate);
                this.offset = isSmallWebScreen(width) ? PRODUCT_COUNT_SMALL_WIDTH : PRODUCT_COUNT_FULL_WIDTH;
                for(let i = 0; i < response.length; i++) {
                    getFetchRequest(makeUrl([RECIPES_URL, response[i].id, '/ingredient/']), 
                        (result) => {
                            this.recipesList[i].ingredients = result;
                            if(i === response.length - 1) {
                                callback && callback(response);
                                console.log("---------------------------------------from loadItems");
                                this.reloadPagesNumber();
                                this.notifyFiltersChangingObservers();
                            }
                        }, errorCallback);
                }
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
        try {
            this.possibleFilters.push(...buildInitialFilters());
            this.possibleFilters.push(...createListOfFilters(productsStorage.getItems(true)));
        } catch(err) {
            console.log(err);
        }
    },

    getMaxCount() {
        return this.maxPages;
    },

    getCurrentPage() {
        return this.page;
    },

    setPageNumber(value) {
        this.page = value;
        this.notifyFiltersChangingObservers();
    },

    getItems(loadAllData) {
        console.log("--------------------------------from getItems");
        const filteredList = this.reloadPagesNumber();
        return loadAllData ? filteredList : filteredList.slice((this.page - 1) * this.offset, this.page * this.offset);
    },

    getInitialFilters(searchParams) {
        const filtersList = [];
        searchParams.forEach((value, key) => {
            const currentFilter = this.possibleFilters.find(it => it.filterName === key);
            if (currentFilter && currentFilter.filterType === FILTER_TYPE_CHECKBOX) {
                currentFilter.value = parseFilterValue(value);
                filtersList.push(currentFilter);
            }
            if (currentFilter && currentFilter.filterType === FILTER_TYPE_SELECT) {
                value.split(',').forEach(it => {
                    filtersList.push({value, filterType: FILTER_TYPE_SELECT, id: it});
                    recipeIngredientService.addItem(it);
                });
            }
        });
        this.filtersList = filtersList;
        this.notifyFiltersChangingObservers();
    },
    getPossibleFilters() {
        return this.possibleFilters;
    },
    getCurrentFilters() {
        return this.filtersList;
    },
    getCurrentFiltersByGroup(groupName) {
        console.log("---------------------------------------getCurrentFiltersByGroup");
        console.log(groupName);
        console.log(this.possibleFilters.filter(it => it.filterGroupName === groupName));
        return this.possibleFilters.filter(it => it.filterGroupName === groupName);
    },
    getFilter(filterName) {
        return this.possibleFilters.find(it => it.filterName === filterName);
    },
    setSearchString(searchStringValue) {
        this.searchValue = searchStringValue;
        console.log("------------------------------------from setSearchString");
        this.reloadPagesNumber();
        this.notifyFiltersChangingObservers();
    },
    getSearchString() {
        return this.searchValue;
    },
    setCheckedFilterValues(filterName, selectedValue) {
        const currentFilter = this.getFilter(filterName);
        if (currentFilter && currentFilter.filterType === FILTER_TYPE_SELECT) {
            selectedValue.split(',').forEach(it => {
                if(it) {
                    this.filtersList.push({value: selectedValue, filterType: FILTER_TYPE_SELECT, id: it});
                }
            });
            this.maxPages = 1;
            this.page = 1;
        }
        if (currentFilter && currentFilter.filterType === FILTER_TYPE_CHECKBOX) {
            currentFilter.value = selectedValue;
            this.filtersList.push(currentFilter);
        }
    },
    setNumberFilterValue() {
    },
    cleanFilterValues() {
        this.filtersList = [];
        this.possibleFilters.forEach(it => {
            it.value = false;
        });
        this.maxPages = Math.ceil(this.recipesList.length / this.offset);
        this.notifyFiltersChangingObservers();
        recipeIngredientService.deleteItem('', true);
    },
    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },
    notifyFiltersChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    reloadPagesNumber() {
        let filteredList = [...this.recipesList];
        if(this.searchValue) {
            filteredList = filterRecipesListBySearchValue(filteredList, this.searchValue);
        }
        if(this.filtersList.length) {
            filteredList = filteredList.filter(it => filterRecipe(it, this.filtersList));
        }
        this.maxPages = Math.ceil(filteredList.length / this.offset);
        return filteredList;
    },
    changeDeletedItemsView() {
    },
    changeValidItemsView() {
    },
};
