import { updateValueSevice } from "../components/sharedComponents/updateValueService";
import {
    PREFERENCE_TYPE_CEREAL_MIX,
    PREFERENCE_TYPE_PRODUCT,
    PREFERENCE_TYPE_PROPERTY,
    PREFERENCE_TYPE_RECIPE,
    PRODUCT_FORM_GRAIN,
    PREFERENCE_TYPE_RECIPE_CHARACTERISTIC,
    RECIPE_CHARACTERISTIC_TASTE,
    RECIPE_TYPES, RECIPE_CHARACTERISTIC_SOURCE,
} from "../constant";
import { i18n } from "../i18n";
import { PHRASE_URL_PART, PREFERENCE_URL, deleteFetchRequest, getFetchRequest, postFetchRequest, putFetchRequest } from "../store/requests";
import { productsStorage } from "./productsStorage";
import { propertiesService } from "./propertiesService";
import { propertiesStorage } from "./propertiesStorage";
import {errorCallback, makeUrl, successCallbackEmpty} from "../util";
import {productsService} from "./productsService";

const DEFAULT_PREFERENCE = {groups: [], combinedPreferences: []};

const createPhraseRequest = (preferenceId, phraseContent, callback) => {
    const preparedPhrase = {
        locale: i18n.APP_LOCALE,
        content: phraseContent.content,
    };
    putFetchRequest(makeUrl([PREFERENCE_URL, preferenceId, PHRASE_URL_PART]), preparedPhrase, callback, errorCallback);
};

const convertToPhraseUpdateBody = (phraseData) => {
    return {
        locale: phraseData.locale,
        content: phraseData.content,
    };
};

const transformGroupsList = (groupsList) => {
    return groupsList.map(it => {
        return { groupId: it.id };
    });
};

const isGrain = (product) => product.productForms.findIndex(it => it.formGroup === PRODUCT_FORM_GRAIN) > -1;

export const createPossibleItemCodeValues = (preferenceType) => {
    if(preferenceType === PREFERENCE_TYPE_PRODUCT) {
        return productsStorage.getItems(true).map(it => {
            return {
                id: it.productCode,
                name: productsService.getLocalization(i18n.APP_LOCALE, it.productCode, true),
            };
        });
    } else if (preferenceType === PREFERENCE_TYPE_CEREAL_MIX) {
        return productsStorage.getItems(true)
            .filter(it => isGrain(it))
            .map(it => {
                return {
                    id: it.productCode,
                    name: productsService.getLocalization(i18n.APP_LOCALE, it.productCode, true),
                };
            });
    } else if (preferenceType === PREFERENCE_TYPE_RECIPE) {
        return RECIPE_TYPES.map(it => {
            return {
                id: it,
                name: i18n.get(`recipe.item.type.${it}`),
            };
        });
    } else if (preferenceType === PREFERENCE_TYPE_RECIPE_CHARACTERISTIC) {
        return [
            {
                id: RECIPE_CHARACTERISTIC_TASTE,
                name: i18n.get(`app.page.recipe.recipeCharacteristic.${RECIPE_CHARACTERISTIC_TASTE}`),
            },
            {
                id: RECIPE_CHARACTERISTIC_SOURCE,
                name: i18n.get(`app.page.recipe.recipeCharacteristic.${RECIPE_CHARACTERISTIC_SOURCE}`),
            },
        ];
    } else if(preferenceType === PREFERENCE_TYPE_PROPERTY) {
        return propertiesStorage.getItems(true).map(it => {
            return {
                id: it.propertyName,
                name: propertiesService.getLocalization(i18n.APP_LOCALE, it.propertyName, false),
            };
        });
    } else {
        return [];
    }
};

const initialPhrase = {id: '', content: '', locale: i18n.APP_LOCALE};

export const preferenceSaveService = {
    currentPreference: {...DEFAULT_PREFERENCE},
    preferenceUpdateObservers: [],
    lastPreferenceUpdateTime: null,
    preferenceId: '',
    phrasesList: [initialPhrase],
    loadPreference() {
        try {
            if(this.preferenceId) {
                getFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, '/']), (response) => {
                    this.currentPreference = {...response, groups: response.groups ? response.groups : []};
                    this.notifyPreferenceUpdateObservers();
                }, errorCallback);
                getFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, PHRASE_URL_PART, '?onlyGenerated=false']), (response) => {
                    if(response.length) {
                        this.phrasesList = [...response, ...this.phrasesList];
                    }
                    this.notifyPreferenceUpdateObservers();
                }, errorCallback);
            }
        } catch(err) {
            console.log(err);
        }
    },
    getPreference() {
        return this.currentPreference;
    },
    updateCombinedPreference(index, propertyKey, value) {
        try {
            if (index === undefined) {
                this.currentPreference.combinedPreferences.push({});
                this.currentPreference.combinedPreferences[this.currentPreference.combinedPreferences.length - 1][propertyKey] = value;
                if(propertyKey === 'preferenceType') {
                    this.currentPreference.combinedPreferences[this.currentPreference.combinedPreferences.length - 1].preferenceItemCodes = [];
                }
            } else {
                this.currentPreference.combinedPreferences[index][propertyKey] = value;
                if(propertyKey === 'preferenceType') {
                    this.currentPreference.combinedPreferences[index].preferenceItemCodes = [];
                }
            }
            this.lastPreferenceUpdateTime = Date.now();
            if(this.preferenceId) {
                this.savePreference();
            }
            this.notifyPreferenceUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    updatePreferenceProperty(propertyKey, value) {
        try {
            this.currentPreference[propertyKey] = value;  
            this.lastPreferenceUpdateTime = Date.now(); 
            if(propertyKey === 'preferenceType') {
                this.currentPreference.preferenceItemCodes = [];
            }
            if(this.preferenceId) {
                this.savePreference();
            }
            this.notifyPreferenceUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    savePreference(callback) {
        try {
            const preparedBody = {
                ...this.currentPreference,
                frequencyPeriod: this.currentPreference.frequencyPeriod ? this.currentPreference.frequencyPeriod : '0',
                groups: Array.isArray(this.currentPreference.groups) ? this.currentPreference.groups : [this.currentPreference.groups],
            };
            if(this.preferenceId) {
                postFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, '/']), preparedBody, successCallbackEmpty, errorCallback, true);
            } else {
                postFetchRequest(makeUrl([PREFERENCE_URL]), preparedBody, (response) => {
                    for(let phrase of this.phrasesList) {
                        phrase.content !== '' && createPhraseRequest(response.id, phrase, () => this.cleanService());
                    }
                    postFetchRequest(makeUrl([PREFERENCE_URL, response.id, '/group/']), transformGroupsList(preparedBody.groups), () => {
                        this.lastPreferenceUpdateTime = Date.now();
                        this.cleanService();
                        callback && callback();
                    }, errorCallback, true);
                }, errorCallback);
            }
        } catch(err) {
            console.log(err);
        }
    },
    updateHowOften(frequencyType, frequencyPeriod) {
        this.currentPreference.preferenceFrequency = frequencyType;
        this.currentPreference.frequencyPeriod = frequencyPeriod;
        if(this.preferenceId) {
            this.savePreference();
        }
        this.lastPreferenceUpdateTime = Date.now();
        this.notifyPreferenceUpdateObservers();
    },
    updatePreferencePropertyGroup(group) {
        const index = this.currentPreference.groups.findIndex(it => it.id === group.id);
        if(index >= 0) {
            this.currentPreference.groups = this.currentPreference.groups.filter(it => it.id !== group.id);
            if(this.preferenceId) {
                deleteFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, '/group/']), () => {
                    this.lastPreferenceUpdateTime = Date.now();
                    this.notifyPreferenceUpdateObservers();
                }, errorCallback, [{groupId: group.id}]);
            }
        } else {
            this.currentPreference.groups.push(group);
            if(this.preferenceId) {
                postFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, '/group/']), [{groupId: group.id}], () => {
                    this.lastPreferenceUpdateTime = Date.now();
                    this.notifyPreferenceUpdateObservers();
                }, errorCallback, true);
            }
        }
    },
    getPhrases() {
        return this.phrasesList;
    },
    updatePreferencePhrases(value, index) {
        //this.currentPhrase.content = value;
        //updateValueSevice.updateItemValue(() => {
        //    postFetchRequest(makeUrl([PREFERENCE_URL, i18n.APP_LOCALE, PHRASE_URL_PART, this.phraseId, '/']),
        //        {content: value}, successCallbackEmpty, errorCallback, true);
        //});
        if (this.phrasesList.length === index) {
            this.phrasesList.push(initialPhrase);
        }
        this.phrasesList[index].content = value;
        if(this.preferenceId) {
            updateValueSevice.updateItemValue(() => {
                if(!this.phrasesList[index].id) {
                    createPhraseRequest(this.preferenceId, this.phrasesList[index], (response) => {
                        this.phrasesList[index] = response;
                        this.lastPreferenceUpdateTime = Date.now();
                        this.notifyPreferenceUpdateObservers();
                    }, errorCallback);
                } else {
                    console.log(this.phrasesList[index]);
                    postFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, PHRASE_URL_PART, this.phrasesList[index].id, '/']), 
                        convertToPhraseUpdateBody(this.phrasesList[index]), () => {
                            this.lastPreferenceUpdateTime = Date.now();
                            this.notifyPreferenceUpdateObservers();
                        }, errorCallback, true);
                }
            });
        }
    },
    deletePhrase(index) {
        this.phrasesList = this.phrasesList.filter((it, ind) => ind !== index);
        if(this.preferenceId && this.phrasesList[index].id) {
            deleteFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, PHRASE_URL_PART, this.phrasesList[index].id, '/']), () => {
                this.lastPreferenceUpdateTime = Date.now();
                this.notifyPreferenceUpdateObservers();
            }, errorCallback);
        } else {
            this.lastPreferenceUpdateTime = Date.now();
            this.notifyPreferenceUpdateObservers();
        }
    },
    registerItemUpdateObserver(observer) {
        this.preferenceUpdateObservers.push(observer);
    },
    unRegisterItemUpdateObserver(observer) {
        this.preferenceUpdateObservers = this.preferenceUpdateObservers.filter(preferenceUpdateFunction => preferenceUpdateFunction !== observer);
    },
    notifyPreferenceUpdateObservers() {
        this.preferenceUpdateObservers.forEach(preferenceUpdateObserver => preferenceUpdateObserver());
    },
    getDateLastUpdate() {
        return this.lastPreferenceUpdateTime;
    },
    cleanService() {
        this.currentPreference = {...DEFAULT_PREFERENCE};
        this.phrasesList = [initialPhrase];
    },
    getPossibleCodeValues(preferenceType = null) {
        if(this.itemCode) {
            return createPossibleItemCodeValues(this.currentPreference.preferenceType).filter(it => it.name.includes(this.itemCode));
        }
        return createPossibleItemCodeValues(this.currentPreference.preferenceType ? this.currentPreference.preferenceType : preferenceType);
    },
    updatePreferencePropertyValue(key, value) {
        this.currentPreference.preferenceItemCodes[0][key] = value;
        this.lastPreferenceUpdateTime = Date.now(); 
        if(this.preferenceId) {
            this.savePreference();
        }
        this.notifyPreferenceUpdateObservers();
    },
    getProperty() {
        return this.currentPreference.preferenceItemCodes[0];
    },
    updateProductProperty(propertyId, fieldName, value) {
        this.updatePreferencePropertyValue(fieldName, value);
    },
    updatePropertyValueField(propertyValue) {
        try {
            if(!this.getProperty().value) {
                this.getProperty().value = propertyValue;
                this.updatePreferencePropertyValue('value', propertyValue);
            }
            this.notifyPreferenceUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    updateValueLocalization(locale, value, code) {
        try {
            i18n.update(`product.scheme.property.value.${code}`, value, locale);
            updateValueSevice.updateLocaleValue(locale, `product.scheme.property.value.${code}`, () => {});
        } catch(err) {
            console.log(err);
        }
    },
    needToAddNewPhrase() {
        return true;
    },
};

export const initializePreferenceSaveService = (preferenceId) => {
    preferenceSaveService.preferenceId = preferenceId;
    preferenceSaveService.loadPreference();
};
