import React from 'react';
import ShowWelcomePhrasesSwitcher from './ShowWelcomePhrasesSwitcher';
import SortingDirectionIcon from './SortingDirectionIcon';
import DeletePhraseIcon from './DeletePhraseIcon';

const PhrasesActions = () => {
    return (
        <div className='preference__phrases-actions'>
            <ShowWelcomePhrasesSwitcher />
            <SortingDirectionIcon />
            <DeletePhraseIcon />
        </div>
    );
};

export default PhrasesActions;
