import { broadcastChannelService } from "../components/sharedComponents/broadcastChannelService";

export function getLang() {
    if (navigator.languages != undefined) 
        return navigator.languages[0]; 
    return navigator.language;
}

export const i18n = {
    en: {},
    ru: {},
    APP_LOCALE: '',
    observersList: new Set(),

    get(key, selectedLocale) {
        const locale = selectedLocale ? selectedLocale : this.APP_LOCALE ? this.APP_LOCALE : getLang().split('-')[0];
        if(key in this[locale]) {
            return this[locale][key];
        } else {
            return key;
        }
    },

    update(path, value, localeCode) {
        this[localeCode][path] = value;
        broadcastChannelService.sendLocaleMessage(this[localeCode], localeCode);
    },

    create(data, localeCode) {
        this[localeCode] = data[localeCode];
        this.notifyObservers();
    },

    is_exists(key, locale) {
        return key in this[locale];
    },

    registerChangingObserver(observer) {
        this.observersList.add(observer);
    },

    unRegisterChangingObserver(observer) {
        this.observersList.delete(observer);
    },

    notifyObservers() {
        this.observersList.forEach(observer => observer());
    },
        
};
