import React, { useEffect, useState } from 'react';
import { PREFERENCE_PHRASE } from '../../constant';
import { i18n } from '../../i18n';
import { MinusIcon } from '../../appearence/icons/minus_icon';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const PreferenceStructurePhrase = ({ itemSaveService }) => {
    const [phrasesValue, setPhrasesValue] = useState(itemSaveService.getPhrases());
    const changePreferenceValue = (e, index) => {
        phrasesValue[index].content = e.target.value;
        setPhrasesValue([...phrasesValue]);
        itemSaveService.updatePreferencePhrases(e.target.value, index);
    };

    const handleAddNewPhraseForm = () => {
        phrasesValue.push({id: '', content: '', locale: i18n.APP_LOCALE});
        setPhrasesValue([...phrasesValue]);
    };

    const deletePhraseItem = (index) => {
        setPhrasesValue(phrasesValue.filter((it, ind) => ind !== index));
        itemSaveService.deletePhrase(index);
    };

    const changePhrasesList = () => {
        setPhrasesValue(itemSaveService.getPhrases());
    };

    useEffect(() => {
        itemSaveService.registerItemUpdateObserver(changePhrasesList);
        return () => itemSaveService.unRegisterItemUpdateObserver(changePhrasesList);
    }, []);

    return (
        <label className="block__text preference__form-label">
            <span>{i18n.get(`app.page.preference.addTitles.phrase`)}</span>
            {phrasesValue.map((phrase, index) => (
                <p key={index + PREFERENCE_PHRASE} className='preference__form-block'>
                    <input className={`block__content preference__form-input ${phrase.content ? 'preference__form-input_filled' : ''}`} 
                        placeholder={capitalizeFirstLetter(i18n.get('app.page.preference.enterPhrase'))} 
                        onChange={(e) => changePreferenceValue(e, index)}
                        value={phrase.content}
                        onBlur={() => itemSaveService.needToAddNewPhrase() && index === phrasesValue.length - 1 ? handleAddNewPhraseForm() : null} />
                    {index !== 0 ?
                        <span onClick={() => deletePhraseItem(index)}>
                            <MinusIcon nameOfClass="preference__form-icon" />
                        </span>
                        :
                        null}
                </p>
                
            ))}
        </label> 
    );
};

export default PreferenceStructurePhrase;
