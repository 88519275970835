import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from '../../i18n';
import {
    LOCALE_RU,
    PREFERENCE_TYPE_PROPERTY,
    PREFERENCE_TYPE_RECIPE,
    PREFERENCE_TYPE_RECIPE_CHARACTERISTIC,
    TABLET_WIDTH,
} from '../../constant';
import useWindowDimensions from '../../hooks/useWindowDimension';
import CustomCheckbox from '../CustomCheckbox';
import { preferencesStorage } from '../../services/preferencesStorage';
import PreferencesListItemUsers from './PreferencesListItemUsers';
import PreferencesListItemGroups from './PreferencesListItemGroups';
import { useOnScreen } from '../../hooks/useOnScreen';
import { productsService } from '../../services/productsService';
import { propertiesService } from '../../services/propertiesService';

export const FREQUENCY_PERIOD_NO_MATTER = 'no-matter';
export const FREQUENCY_PERIOD_ONCE_A_PERIOD = 'once-a-period';
export const FREQUENCY_PERIOD_EVERY_DAY = 'everyDay';
export const FREQUENCY_PERIOD_DAY_AFTER_DAY = 'dayAfterDay';
export const FREQUENCY_PERIOD_ONCE_IN_DAYS = 'onceInDays';

const findFrequencyPeriodValue = (preferenceFrequency, frequencyPeriod) => {
    if(preferenceFrequency === FREQUENCY_PERIOD_NO_MATTER) {
        return FREQUENCY_PERIOD_NO_MATTER;
    }
    if(preferenceFrequency === FREQUENCY_PERIOD_ONCE_A_PERIOD && frequencyPeriod === 1) {
        return FREQUENCY_PERIOD_EVERY_DAY;
    }
    if(preferenceFrequency === FREQUENCY_PERIOD_ONCE_A_PERIOD && frequencyPeriod === 2) {
        return FREQUENCY_PERIOD_DAY_AFTER_DAY;
    }
    if (frequencyPeriod > 2) {
        return FREQUENCY_PERIOD_ONCE_IN_DAYS;
    }
};

export const findTranslationValue = (frequencyPeriod) => {
    if (i18n.APP_LOCALE === LOCALE_RU) {
        if (frequencyPeriod > 10 && [11, 12, 13, 14].includes(frequencyPeriod % 100)) return 'дней';
        let last_num = frequencyPeriod % 10;
        if (last_num == 1) return 'день';
        if ([2, 3, 4].includes(last_num)) return 'дня';
        if ([5, 6, 7, 8, 9, 0].includes(last_num)) return 'дней';
    } else {
        return 'days';
    }
};

export const findFrequencyTitle = (preferenceFrequency, frequencyPeriod) => {
    if(+frequencyPeriod > 2 && preferenceFrequency === FREQUENCY_PERIOD_ONCE_A_PERIOD) {
        return i18n.get(`preference.scheme.frequency.${findFrequencyPeriodValue(preferenceFrequency, frequencyPeriod)}`) 
            + ` ${frequencyPeriod} ${findTranslationValue(frequencyPeriod)}`;
    } else {
        return i18n.get(`preference.scheme.frequency.${findFrequencyPeriodValue(preferenceFrequency, frequencyPeriod)}`);
    }
};

export const createPreferenceItemCodesValue = (preference) => {
    if(preference.preferenceItemCodes && preference.preferenceItemCodes.length) {
        const result = [];
        for(let code of preference.preferenceItemCodes) {
            if(preference.preferenceType === PREFERENCE_TYPE_RECIPE) {
                result.push(i18n.get(`recipe.scheme.recipeType.${code.itemCode}`));
            } else if(preference.preferenceType === PREFERENCE_TYPE_RECIPE_CHARACTERISTIC) {
                return i18n.get(`app.page.recipe.recipeCharacteristic.${code.itemCode}`) + ", " + i18n.get(`recipe.scheme.characteristic.${code.itemCode}.${code.value}`) ;
            } else if(preference.preferenceType === PREFERENCE_TYPE_PROPERTY) {
                result.push(propertiesService.getLocalization(i18n.APP_LOCALE, code.itemCode, true));
            } else {
                result.push(productsService.getLocalization(i18n.APP_LOCALE, code.itemCode, true));
            }
        }
        return result.join(', ');
    }
    return '';
};

const PreferencesListItem = ({preference}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [phrasesCount, setPhrasesCount] = useState(0);
    const { width } = useWindowDimensions();
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);
    const navigate = useNavigate();

    const changeCheckedValue = () => {
        setIsChecked(preferencesStorage.getSelectedPreferencesList().findIndex(it => it.id === preference.id) >= 0);
        preferencesStorage.getById(preference.id)?.count && setPhrasesCount(preferencesStorage.getById(preference.id).count);
    };

    const changeSelectedList = () => {
        preferencesStorage.changeSelectedPreferencesList(preference.id);
    };

    useEffect(() => {
        preferencesStorage.getPreferencePhrasesCount(preference.id, (response) => setPhrasesCount(response.count));
    }, [isVisible]);

    useEffect(() => {
        preferencesStorage.registerFiltersChangingObserver(changeCheckedValue);
        return () => preferencesStorage.unRegisterFiltersChangingObserver(changeCheckedValue);
    }, []);

    return (
        <>
            <li className="preference__list-item" ref={ref}>
                <CustomCheckbox checked={isChecked} handleChange={changeSelectedList} />
                <ul className="preference__item-row">
                    <li className="preference__item">
                        <span className="preference__item-header">{i18n.get('app.page.preference.addTitles.include')}</span>
                        <span className="preference__item-content">{i18n.get(`preference.scheme.include.${preference.include}`)}</span>
                    </li>
                    <li className="preference__item">
                        <span className="preference__item-header">{i18n.get('app.page.preference.addTitles.preferenceType')}</span>
                        <span className="preference__item-content">{i18n.get(`preference.scheme.preferenceType.${preference.preferenceType}`)}</span>
                    </li>
                    <li className="preference__item">
                        <span className="preference__item-header">{i18n.get('app.page.preference.addTitles.preferenceItemCode')}</span>
                        <span className="preference__item-content">{createPreferenceItemCodesValue(preference)}</span>
                    </li>
                    {width <= TABLET_WIDTH ? 
                        null 
                        :  
                        <li className="preference__item">
                            <span className="preference__item-header">{i18n.get('app.page.preference.addTitles.when')}</span>
                            <span className="preference__item-content">{i18n.get(`preference.scheme.when.${preference.when}`)}</span>
                        </li>
                    }
                    {width <= TABLET_WIDTH ? 
                        null 
                        :  
                        <li className="preference__item">
                            <span className="preference__item-header">{i18n.get('app.page.preference.addTitles.howOften')}</span>
                            <span className="preference__item-content">{findFrequencyTitle(preference.preferenceFrequency, +preference.frequencyPeriod)}</span>
                        </li>
                    }
                </ul>
                <div className="preference__item-icons">
                    <PreferencesListItemGroups preferenceId={preference.id} />
                    <PreferencesListItemUsers preferenceId={preference.id} />
                    <p className="preference__item-count" onClick={() => navigate(preferencesStorage.getPathForPhrasesPage(preference))}>
                        <span className="preference__item-quantity">{phrasesCount}</span>
                    </p>
                </div>
            </li>
        </>
    );
};

export default PreferencesListItem;
