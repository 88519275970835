import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {PAGE_TAB_NAME_NEW, PREFERENCE_TYPE_COMBINATION} from '../../constant';
import PageTitleAsBreadCrumbs from '../sharedComponents/PageTitleAsBreadCrumbs';
import LastUpdateTime from '../sharedComponents/LastUpdateTime';
import SaveNewPreferenceButton from './SaveNewPreferenceButton';
import { initializePreferenceSaveService, preferenceSaveService } from '../../services/preferenceSaveService';
import PreferenceStructureIncluded from './PreferenceStructureIncluded';
import PreferenceStructureType from './PreferenceStructureType';
import PreferenceStructureItemCode from './PreferenceStructureItemCode';
import PreferenceStructureWhen from './PreferenceStructureWhen';
import PreferenceStructureItemHowOften from './PreferenceStructureItemHowOften';
import PreferenceStructureGroup from './PreferenceStructureGroup';
import PreferenceStructurePhrase from './PreferenceStructurePhrase';
import { createPreferenceSelectGroupService } from '../../services/preferenceGroupSelectService';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import { productsStorage } from '../../services/productsStorage';
import { propertiesStorage } from '../../services/propertiesStorage';


const PreferenceItemEdit = () => {
    const { id } = useParams();
    const [preferenceIsReady, setPreferenceIsReady] = useState(null);

    const changePreferenceReadyState = () => {
        setPreferenceIsReady(uuid());
    };

    const getFormKey = () => {
        return (PAGE_TAB_NAME_NEW || id) + preferenceIsReady;
    };

    useEffect(() => {
        if(id !== PAGE_TAB_NAME_NEW) {
            initializePreferenceSaveService(id);
        } 
        propertiesStorage.loadItems(() => {});
        productsStorage.loadItems(() => {});
        preferencesGroupsStorage.loadItems();
        preferenceSaveService.registerItemUpdateObserver(changePreferenceReadyState);
        return () => {
            preferenceSaveService.unRegisterItemUpdateObserver(changePreferenceReadyState);
            preferenceSaveService.cleanService();
        };
    }, [id]);

    return (
        <>
            <PageTitleAsBreadCrumbs />
            <article className="preference__article preference__article_full-width preference__article-add">
                <form className="preference__form" key={getFormKey()}>
                    {id === PAGE_TAB_NAME_NEW ?
                        <SaveNewPreferenceButton />
                        :
                        <LastUpdateTime itemSaveService={preferenceSaveService} />}
                    <div className="preference__form-column">
                        <PreferenceStructureIncluded itemSaveService={preferenceSaveService} />
                        <PreferenceStructureType itemSaveService={preferenceSaveService} parentMode={true}/>
                        {preferenceSaveService.getPreference().preferenceType === PREFERENCE_TYPE_COMBINATION ?
                            <div className="preference__form-combination">
                                {preferenceSaveService.getPreference().combinedPreferences.map((it, index) => (
                                    <React.Fragment key={it.id + index}>
                                        <PreferenceStructureType itemSaveService={preferenceSaveService} index={index} parentMode={false}/>
                                        <PreferenceStructureItemCode itemSaveService={preferenceSaveService} index={index} />
                                    </React.Fragment>
                                ))}
                                <PreferenceStructureType itemSaveService={preferenceSaveService} parentMode={false}/>
                                <PreferenceStructureItemCode itemSaveService={preferenceSaveService} />
                            </div>
                            :
                            <PreferenceStructureItemCode itemSaveService={preferenceSaveService} />
                        }
                        <PreferenceStructureWhen itemSaveService={preferenceSaveService} />
                        
                    </div>
                    <div className="preference__form-column">
                        <PreferenceStructureItemHowOften itemSaveService={preferenceSaveService} />
                        <PreferenceStructureGroup selectWithTokensManager={createPreferenceSelectGroupService()} />
                        <PreferenceStructurePhrase itemSaveService={preferenceSaveService} />
                    </div>
                </form>
            </article>
        </>
    );
};

export default PreferenceItemEdit;
