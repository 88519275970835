import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchItemBlock from '../sharedComponents/SearchItemBlock';
import { Pagination } from '../Pagination';
import { i18n } from '../../i18n/index';
import { recipesStorage } from '../../services/recipesStorage';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { productsStorage } from '../../services/productsStorage';
import DeleteRecipePopup from './DeleteRecipePopup';
import RecipesList from './RecipesList';
import CreateNewRecipePopup from './CreateNewRecipePopup';
import CreateNewRecipeButton from './CreateNewRecipeButton';

const RecipesListTab = () => {
    const [searchParams] = useSearchParams();
    const { width } = useWindowDimensions();

    const getRecipesItems = () => {
        recipesStorage.loadItems(() => {}, width);
        recipesStorage.getInitialFilters(searchParams);
    };

    useEffect(() => {
        productsStorage.loadItems(getRecipesItems, width);
        return () => {
            recipesStorage.setPageNumber(1);
        };
    }, []);

    const notifyRecipesList = () => {
        recipesStorage.loadItems(() => {}, width);
    };

    return (
        <section className="workspace workspace__recipe">
            <DeleteRecipePopup notifyRecipesList={notifyRecipesList} />
            <CreateNewRecipePopup />
            <h1 className="recipe__title block__title-h2">{i18n.get('app.page.recipe.title')}</h1>
            <SearchItemBlock itemsStorageService={recipesStorage} placeholder={i18n.get('app.page.recipe.recipeSearch')} />
            <CreateNewRecipeButton />
            <article className="recipe__article-item">
                <RecipesList />
            </article>
            <Pagination itemsStorageService={recipesStorage} />
        </section>
    );
};

export default RecipesListTab;
