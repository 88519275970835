import { DATE_START_KEY, DATE_START_LABEL, DATE_STOP_KEY, LOCALE_EN, LOCALE_RU } from "../constant";
import { i18n } from "../i18n";
import { PREFERENCE_GROUP_URL, getFetchRequest, postFetchRequest } from "../store/requests";
import { v4 as uuid } from 'uuid';
import { preferencesGroupsService } from "./preferencesGroupsService";
import { updateValueSevice } from "../components/sharedComponents/updateValueService";
import { checkItemLocaleCode } from "../components/helper";
import {errorCallback, makeUrl, successCallbackEmpty} from "../util";

export const transformLabelTypeToKey = (labelType) => {
    return labelType === DATE_START_LABEL ? DATE_START_KEY : DATE_STOP_KEY;
};

const createPreferenceGroupName = () => {
    return uuid().slice(0, 4) + uuid().slice(0, 4);
};

const createLocales = (code) => {
    return [{
        [LOCALE_RU]: preferencesGroupsService.getLocalization(LOCALE_RU, code, false), 
        [LOCALE_EN]: preferencesGroupsService.getLocalization(LOCALE_EN, code, false),
    }];
};

const needToAddValue = (group, index) => {
    return group.activityPeriods.length - 1 === index && 
        group.activityPeriods[index] &&
        group.activityPeriods[index].dateStart && 
        group.activityPeriods[index].dateStop;
};

const prepareActivityPeriods = (periodsList) => {
    const listOfPeriods = [];
    if(periodsList && periodsList.length) {
        for(let period of periodsList) {
            const splittedDateStart = period.dateStart.split('.');
            const splittedDateStop = period.dateStop.split('.');
            period.dateStart && period.dateStop ?
                listOfPeriods.push({
                    dateStart: new Date(new Date(Date.now()).getFullYear(), splittedDateStart[1] - 1, +splittedDateStart[0] + 1).toISOString(),
                    dateStop: new Date(new Date(Date.now()).getFullYear(), splittedDateStop[1] - 1, +splittedDateStop[0] + 1).toISOString(),
                })
                :
                null;
        }
    }
    return listOfPeriods;
};

const transformActivityPeriods = (periodsList) => {
    const listOfPeriods = [];
    if(periodsList && periodsList.length) {
        for(let period of periodsList) {
            listOfPeriods.push({
                dateStart: new Date(period.dateStart).toLocaleString('ru-RU').substring(0, 6),
                dateStop: new Date(period.dateStop).toLocaleString('ru-RU').substring(0, 6),
            });
        }
    }
    return listOfPeriods;
};

export const preferenceGroupSaveService = {
    currentGroup: {isPermanent: true, name: createPreferenceGroupName(), isAutoAssigned: false, locales: []},
    observersList: [],
    preferenceGroupId: '',
    loadPreferenceGroup() {
        try {
            if(this.preferenceGroupId) {
                getFetchRequest(makeUrl([PREFERENCE_GROUP_URL, this.preferenceGroupId, '/']), (response) => {
                    this.currentGroup = {...response, activityPeriods: transformActivityPeriods(response.activityPeriods), locales: createLocales(response.name)};
                    this.notifyPreferenceGroupUpdateObservers();
                }, errorCallback);
            }
        } catch(err) {
            console.log(err);
        }
    },
    updateItemLocalization(locale, value) {
        try {
            i18n.update(`preference.scheme.group.code.${this.currentGroup.name}`, value, locale);
            if (this.currentGroup.locales && this.currentGroup.locales.length) {
                const index = this.currentGroup.locales.findIndex(it => it.localeCode === locale);
                index >= 0 ?
                    this.currentGroup.locales[index].localeValue = value
                    :
                    this.currentGroup.locales.push({localeCode: locale, localeValue:value});
            } else {
                this.currentGroup.locales.push({localeCode: locale, localeValue: value});
            }
            if(checkItemLocaleCode(this.currentGroup.name)) {
                updateValueSevice.updateLocaleValue(locale, `preference.scheme.group.code.${this.currentGroup.name}`, () => {
                    this.lastProductUpdateTime = Date.now();
                });
            }
        } catch(err) {
            console.log(err);
        }
    },
    updatePreferenceGroupAutoAssigned(value) {
        console.log("-----------------------------------------updatePreferenceGroupAutoAssigned");
        console.log(value);
        this.currentGroup.isAutoAssigned = value;
        this.notifyPreferenceGroupUpdateObservers();
    },
    getPreferenceGroup() {
        return this.currentGroup;
    },
    updatePreferenceGroupActivityType(isPermanent) {
        this.currentGroup.isPermanent = isPermanent;
        if(!isPermanent) {
            this.currentGroup.activityPeriods = null;
            this.updatePreferenceGroupActivityPeriod();
        }
        this.notifyPreferenceGroupUpdateObservers();
    },
    updatePreferenceGroupActivityPeriod(activityPeriodKey, activityPeriodValue, currentPeriodIndex) {
        if(!this.currentGroup.activityPeriods) {
            this.currentGroup.activityPeriods = [{dateStart: '', dateStop: ''}];
        } else {
            this.currentGroup.activityPeriods[currentPeriodIndex] = {...this.currentGroup.activityPeriods[currentPeriodIndex], 
                [transformLabelTypeToKey(activityPeriodKey)]: activityPeriodValue};
        }
        if(needToAddValue(this.currentGroup, currentPeriodIndex)) {
            this.currentGroup.activityPeriods.push({dateStart: '', dateStop: ''});
        }
        this.notifyPreferenceGroupUpdateObservers();
    },
    registerPreferenceGroupUpdateObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterPreferenceGroupUpdateObserver(observer) {
        this.observersList = this.observersList.filter(observerFunction => observerFunction !== observer);
    },
    notifyPreferenceGroupUpdateObservers() {
        this.observersList.forEach(observer => observer());
    },
    deleteActivityPeriod(periodIndex) {
        this.currentGroup.activityPeriods = this.currentGroup.activityPeriods.filter((it, index) => index !== periodIndex);
        this.notifyPreferenceGroupUpdateObservers();
    },
    savePreferenceGroup() {
        console.log("----------------------savePreferenceGroup");
        console.log(this.currentGroup);
        const preparedBody = {
            isAutoAssigned: this.currentGroup.isAutoAssigned,
            isPermanent: this.currentGroup.isPermanent,
            activityPeriods: prepareActivityPeriods(this.currentGroup.activityPeriods),
            name: this.currentGroup.name,
        };
        if(this.preferenceGroupId) {
            postFetchRequest(makeUrl([PREFERENCE_GROUP_URL, this.preferenceGroupId, '/']), preparedBody, successCallbackEmpty, errorCallback, true);
        } else {
            postFetchRequest(makeUrl([PREFERENCE_GROUP_URL]), preparedBody, successCallbackEmpty, errorCallback, true);
        }
    },
    clearService() {
        this.currentGroup = {isPermanent: true, name: createPreferenceGroupName(), isAutoAssigned: false, locales: []};
    },
};

export const initializePreferenceGroupSaveService = (preferenceGroupId) => {
    preferenceGroupSaveService.preferenceGroupId = preferenceGroupId;
    preferenceGroupSaveService.loadPreferenceGroup();
};
