import React, { useState, useEffect } from 'react';
import LoginPage from './components/LoginPage';
import { Routes, Route, Navigate } from 'react-router-dom';
import { GET_CURRENT_USER_REQUEST, LOCALES_URL, getFetchRequest } from './store/requests';
import { CHANGE_VISIBILITY_EVENT, LOCALE_EN, LOCALE_RU, VISIBILITY_STATE_VISIBLE } from './constant';
import ProfilePage from './components/ProfilePage';
import UsersPage from './components/UsersPage';
import ProductPage from './components/ProductPage';
import RecipePage from './components/recipes/RecipePage';
import ResetPasswordPage from './components/ResetPasswordPage';
import PreferencePage from './components/PreferencePage';
import { i18n } from './i18n';
import WithWrapper from './components/WithMenu';
import { handleCreateNewWebSocketAsyncService, registerObserver, unRegisterObserver } from './components/sharedComponents/WebSocketConnectionService';
import { broadcastChannelService } from './components/sharedComponents/broadcastChannelService';
import {makeUrl, successCallbackEmpty} from "./util";


const App = () => {
    const [accessToken, setAccessToken] = useState(null);
    const [localeIsReady, setLocaleIsReady] = useState(false);
    const [currentUserData, setCurrentUserData] = useState({name:'', email:''});
    
    const successGetLocaleCallback = (response) => {
        i18n.create(response, LOCALE_RU);
        getFetchRequest(makeUrl([LOCALES_URL, LOCALE_EN, '/']), (result) => {
            i18n.create(result, LOCALE_EN);
            setLocaleIsReady(true);
        }, (err) => {
            setLocaleIsReady(true);
            console.log(err);
        }); 
    }; 

    const successCallback = async (response) => {
        if(response.status === 200) {
            try{
                const result = await response.json();
                result.id ? setAccessToken(true) : setAccessToken(false);
                i18n.APP_LOCALE = result.locale;
                console.log(result);
                console.log(i18n.APP_LOCALE);
                Object.keys(i18n.ru).length ? 
                    null 
                    : 
                    getFetchRequest(makeUrl([LOCALES_URL, LOCALE_RU, '/']), successGetLocaleCallback, (err) => {
                        setLocaleIsReady(true);
                        setAccessToken(false);
                        console.log(err);
                    });
                setCurrentUserData(result);
            } catch(err) {
                setAccessToken(false);
            }
        } else {
            setAccessToken(false);
            getFetchRequest(makeUrl([LOCALES_URL, LOCALE_RU, '/']), successGetLocaleCallback, (err) => {
                setLocaleIsReady(true);
                setAccessToken(false);
                console.log(err);
            });
        }
    };

    const errorCallback = (err) => {
        console.log(err);
        setAccessToken(false);
        getFetchRequest(makeUrl([LOCALES_URL, LOCALE_RU, '/']), successGetLocaleCallback, (err) => {
            setLocaleIsReady(true);
            console.log(err);
        });
    };
    useEffect(()=> {
        if(accessToken !== false && !currentUserData.id) {
            getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]), successCallback, errorCallback, true);
        }
    },[accessToken]);    

    useEffect(() => {
        setCurrentUserData({...currentUserData, locale: i18n.APP_LOCALE});
    }, [localeIsReady]);

    const changeWSMessage = (message) => {
        console.log(message);
    };

    useEffect(() => {
        broadcastChannelService.createLocaleConnection();
        broadcastChannelService.createFormUpdateConnection();
        document.addEventListener(CHANGE_VISIBILITY_EVENT, () => {
            if (document.visibilityState == VISIBILITY_STATE_VISIBLE) {
                getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]), successCallbackEmpty, errorCallback);
            } 
        });
    }, []);

    useEffect(() => {
        if(accessToken) {
            handleCreateNewWebSocketAsyncService();
            registerObserver(changeWSMessage);
        }
        return () => unRegisterObserver(changeWSMessage);
    }, [accessToken]);

    const ProfilePageWithWrapper = WithWrapper(ProfilePage, setAccessToken);
    const UsersPageWithWrapper = WithWrapper(UsersPage, setAccessToken);
    const ProductPageWithWrapper = WithWrapper(ProductPage, setAccessToken);
    const PreferencePageWithWrapper = WithWrapper(PreferencePage, setAccessToken);
    const RecipePageWithWrapper = WithWrapper(RecipePage, setAccessToken);

    if(accessToken === null || !localeIsReady) {
        return null;
    } else if(accessToken === false) {
        return (
            <Routes>
                <Route exact path="/password-reset" element={<ResetPasswordPage />} />
                <Route path="*" element={<LoginPage setAccessToken={setAccessToken}/>} />
            </Routes>
        );
    } else {
        return (
            <Routes> 
                <Route exact path="/" element={<Navigate to="/profile" replace />} />
                <Route exact path="/profile" element={
                    <ProfilePageWithWrapper localeIsReady={localeIsReady} setLocaleIsReady={setLocaleIsReady} currentUserData={currentUserData}/>} />
                <Route exact path="/user" element={<UsersPageWithWrapper currentUserData={currentUserData} />}  />
                <Route exact path="/product/:tab/:id" element={<ProductPageWithWrapper />} />
                <Route exact path="/preference/:tab/:id" element={<PreferencePageWithWrapper />}  />
                <Route exact path="/recipe/:tab/:id" element={<RecipePageWithWrapper />} />
                <Route exact path="/password-reset" element={<ResetPasswordPage />} />
                <Route exact path="/login" element={<LoginPage setAccessToken={setAccessToken}/>} />
                <Route path="*" element={<Navigate to="/profile" replace />} />
            </Routes>
        );
    }
};

export default App;
