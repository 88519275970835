import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ConeIcon } from '../../appearence/icons/cone_icon';
import { i18n } from '../../i18n/index';
import FiltersListItem from './FiltersListItem';
import { filtersListIsOpenStateObject } from './FiltersListIsOpenStateObject';
import FiltersListStatus from './FiltersListStatus';
import { 
    FILTER_GROUP_NAME_STATUS, 
    FILTER_TYPE_CHECKBOX, 
    FILTER_TYPE_MAX_AND_MIN, 
    FILTER_TYPE_SELECT, 
    FILTER_NAME_IS_DELETED, 
    FILTER_NAME_IS_NOT_VALID,
} from '../../constant';

const checkNeedToUseStatus = (filtersList) => {
    return filtersList.filter(it => it.filterGroupName === FILTER_GROUP_NAME_STATUS).length !== 0;
};

const createFiltersGroupList = (listOfFilters) => {
    const preparedArray = [];
    listOfFilters.forEach(it => preparedArray.findIndex(item => item.filterGroupName === it.filterGroupName) >= 0 ? 
        null 
        : 
        preparedArray.push(it));
    return preparedArray;
};

const findDropdownClass = (listLength) => {
    return Math.ceil(listLength / 3) === 1 ? 
        'product__dropdown-list_small' 
        : 
        Math.ceil(listLength / 3) === 2 ? 
            'product__dropdown-list_full-list' 
            : 
            '';
};

const FiltersListDropdown = ({ itemsStorageService }) => {
    const [filtersListIsOpen, setFiltersListIsOpen] = useState(false);
    const [filtersList, setFiltersList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const changeFiltersListState = (value) => {
        setFiltersListIsOpen(value);
    };

    const reloadPossibleFilters = () => {
        setFiltersList(createFiltersGroupList(itemsStorageService.getPossibleFilters().filter(it => it.filterGroupName !== FILTER_GROUP_NAME_STATUS)));
    };

    const cleanFiltersList = () => {
        searchParams.forEach((value, key) => {
            searchParams.delete(key);
        });
        setSearchParams({});
        setFiltersList([]);
        itemsStorageService.cleanFilterValues();
    };

    useEffect(() => {
        filtersListIsOpenStateObject.registerFiltersListObserver(changeFiltersListState);
        itemsStorageService.registerFiltersChangingObserver(reloadPossibleFilters);
        setFiltersList(createFiltersGroupList(itemsStorageService.getPossibleFilters().filter(it => it.filterGroupName !== FILTER_GROUP_NAME_STATUS)));
        return () => {
            itemsStorageService.unRegisterFiltersChangingObserver(reloadPossibleFilters);
            filtersListIsOpenStateObject.unRegisterFiltersListObserver(changeFiltersListState);
        };
    }, []);

    const applyFiltersList = () => {
        searchParams.forEach((value, key) => {
            searchParams.delete(key);
        });
        setSearchParams({});
        const currentFilters = itemsStorageService.getCurrentFilters();
        currentFilters.forEach(filter => {
            if(filter.filterType === FILTER_TYPE_CHECKBOX && filter.value === true) {
                searchParams.set(filter.filterName, filter.value);
            }
            if(filter.filterType === FILTER_TYPE_MAX_AND_MIN && (filter.max || filter.min)) {
                filter.min !== undefined && searchParams.set(`${filter.filterName}.min`, filter.min);
                filter.max !== undefined && searchParams.set(`${filter.filterName}.max`, filter.max);
            }
            if(filter.filterType === FILTER_TYPE_SELECT && filter.value) {
                searchParams.set(filter.filterName, filter.value);
            }
        });
        itemsStorageService.changeDeletedItemsView(
            itemsStorageService.getCurrentFilters().findIndex(it => it.filterName === FILTER_NAME_IS_DELETED) >= 0,
        );
        itemsStorageService.changeValidItemsView(
            itemsStorageService.getCurrentFilters().findIndex(it => it.filterName === FILTER_NAME_IS_NOT_VALID) >= 0,
        );
        setSearchParams(searchParams);
        setFiltersListIsOpen(false);
        console.log("--------------------------from applyFiltersList");
        itemsStorageService.reloadPagesNumber();
        itemsStorageService.notifyFiltersChangingObservers();
    };

    return (
        filtersListIsOpen ? 
            <>
                <div className="modal__wrapper" onClick={() => filtersListIsOpenStateObject.callToOpen(false)}></div>
                <article className="product__article-list product__dropdown">
                    <ConeIcon nameOfClass="product__dropdown-cone" />
                    <div className="product__dropdown-wrapper">
                        <ul className={`product__dropdown-list ${findDropdownClass(filtersList.length)}`}>
                            {filtersList.map(it =>
                                <React.Fragment key={it.filterName + it.filterGroupName}>
                                    <FiltersListItem filterData={it} itemsStorageService={itemsStorageService}/>
                                </React.Fragment>,
                            )}
                            {checkNeedToUseStatus(createFiltersGroupList(itemsStorageService.getPossibleFilters())) ? 
                                <FiltersListStatus itemsStorageService={itemsStorageService} /> 
                                : 
                                null}
                        </ul>
                    </div>
                    <div className="product__dropdown-buttons">
                        <button className="block__button button button_outline product__dropdown-button" 
                            onClick={() => filtersListIsOpenStateObject.callToOpen(false)}>
                            <span className="button__content">{i18n.get(`app.page.product.filters.cancel`)}</span>
                        </button>
                        <div className="product__dropdown-block">
                            <button className="block__button product__dropdown-clean" onClick={cleanFiltersList}>
                                <span className="button__content">{i18n.get(`app.page.product.filters.clean`)}</span>
                            </button>
                            <button className="block__button button product__dropdown-button" 
                                onClick={applyFiltersList}>
                                <span className="button__content">{i18n.get(`app.page.product.filters.applyFilter`)}</span>
                            </button>
                        </div>
                    </div>
                </article>
                
            </>
            :
            null
    );
};

export default FiltersListDropdown;
