export const filtersListIsOpenStateObject = {
    observers: new Set(),
    callToOpen(popupParams) {
        try {
            this.observers.forEach(observer => observer(popupParams));
        } catch(err) {
            console.log(err);
        }
    },

    registerFiltersListObserver(openStateFunction) {
        this.observers.add(openStateFunction);
        console.log("-------------------------------------registerFiltersListObserver filtersListIsOpenStateObject");
        console.log(this.observers);
    },

    unRegisterFiltersListObserver(openStateFunction) {
        try {
            this.observers.delete(openStateFunction);
        } catch(err) {
            console.log(err);
        }
    },
};
