import React, { useState, useEffect } from 'react';
import {recipeSaveService} from "../../services/recipeSaveService";
import {DIGITS_REG_EXP} from "../../constant";
import {i18n} from "../../i18n";

const RecipeIngredientNumberValue = ({ingredientsGroup, ingredientsGroupNumber, fieldValue}) => {
    const [currentValue, setCurrentValue] = useState(0);

    const changeIngredientGroupNumber = (e) => {
        console.log("----------------------------changeIngredientGroupNumber");
        console.log(e.target.value);
        if(e.target.value.match(DIGITS_REG_EXP) || e.target.value === '') {
            setCurrentValue(e.target.value);
            recipeSaveService.updateIngredientGroupMaxNumber(ingredientsGroup, ingredientsGroupNumber, e.target.value);
        }
    };

    useEffect(() => {
        setCurrentValue(fieldValue);
    }, [ingredientsGroup, ingredientsGroupNumber]);

    return (
        <label key={ingredientsGroup + ingredientsGroupNumber} className="block__text product__form-label product__add-label">
            <span className="product__form-number product__form-label_text">{ingredientsGroupNumber} {i18n.get('product.scheme.property.code.valueOfData')}</span>
            <input
                className={`product__form-input product__add-input`}
                value={currentValue}
                onChange={changeIngredientGroupNumber}
                name={ingredientsGroup}
            />
        </label>
    );
};

export default RecipeIngredientNumberValue;
