import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { i18n } from '../i18n';
import { ITEM_TAB_NAME,
    PREFERENCE_TAB,
    PAGE_TAB_NAME_LIST,
    PHRASE_TAB_NAME,
    MEAL_TAB_NAME,
    GROUP_TAB_NAME,
    NOTIFICATION_EVENT_TYPE_PHRASES_GENERATED,
    NOTIFICATION_EVENT_TYPE_PHRASES_UPDATED_FAIL,
    NOTIFICATION_TYPE_WARNING,
    NOTIFICATION_EVENT_TYPE_PREFERENCE_UPDATE_FAIL } from '../constant';
import { ErrorHandler } from './ErrorHandler';
import PreferenceItemsTab from './preferences/PreferenceItemsTab';
import PreferenceItemEdit from './preferences/PreferenceItemEdit';
import PreferencePhrasesTab from './preferences/PreferencePhrasesTab';
import PreferenceMealTab from './preferences/PreferenceMealTab';
import PreferenceGroupList from './preferences/PreferenceGroupList';
import PreferenceGroupEdit from './preferences/PreferenceGroupEdit';
import Title from './Title';
import NavigationTabs from './sharedComponents/NavigationTabs';
import { preferenceNavigationService } from '../services/preferencesNavigationService';
import DownloadCSVFileButton from './preferences/DownloadCSVFileButton';
import NotificationToast from './sharedComponents/NotificationToast';
import { NOTIFICATION_TYPE_SUCCESS } from '../constant';
import { registerObserver, unRegisterObserver } from './sharedComponents/WebSocketConnectionService';
import { createValuesString } from './preferences/PhrasesListItem';
import { preferencesStorage } from '../services/preferencesStorage';
import { createPhrasesMessage } from './helper';
import { PHRASE_URL_PART, PREFERENCE_URL, getSyncFetchRequest } from '../store/requests';
import {makeUrl} from "../util";

const PreferencePage = () => {
    const [activeRoute, setActiveRoute] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const {tab, id} = useParams();

    const getPhraseRequest = async (eventType, itemId) => {
        const result = await getSyncFetchRequest(makeUrl([PREFERENCE_URL, i18n.APP_LOCALE, PHRASE_URL_PART, itemId, '/']));
        setToastMessage(createPhrasesMessage(eventType, result.content));
    };

    const getPreferenceRequest = async (eventType, itemId) => {
        const result = await getSyncFetchRequest(makeUrl([PREFERENCE_URL, itemId, '/']));
        setToastMessage(createPhrasesMessage(eventType, createValuesString(result)));
    };

    const changeWsToastMessage = (message) => {
        try {
            const data = JSON.parse(message);
            if(data.body.eventType === NOTIFICATION_EVENT_TYPE_PHRASES_GENERATED) {
                setToastMessage(createPhrasesMessage(data.body.eventType, createValuesString(preferencesStorage.getById(data.body.itemId))));
                setNotificationType(NOTIFICATION_TYPE_SUCCESS);
            } else if(data.body.eventType === NOTIFICATION_EVENT_TYPE_PHRASES_UPDATED_FAIL) {
                getPhraseRequest(data.body.eventType, data.body.itemId);
                setNotificationType(NOTIFICATION_TYPE_WARNING);
            } else if(data.body.eventType === NOTIFICATION_EVENT_TYPE_PREFERENCE_UPDATE_FAIL) {
                getPreferenceRequest(data.body.eventType, data.body.itemId);
                setNotificationType(NOTIFICATION_TYPE_WARNING);
            }
        } catch(err) {
            console.log(err);
        }
    };

    useEffect(() => {
        setActiveRoute(`/${PREFERENCE_TAB}/${tab}/${id}/`);
        registerObserver(changeWsToastMessage);
        return () => {
            unRegisterObserver(changeWsToastMessage);
        };
    }, [tab, id]);

    try {
        if(!activeRoute) return null;
        return (
            <section className="workspace workspace__preference">
                {id !== PAGE_TAB_NAME_LIST ? 
                    null
                    :
                    <>
                        <Title titleValue={i18n.get("app.page.preference.title")}/>
                        <NavigationTabs navigationService={preferenceNavigationService} />
                        <DownloadCSVFileButton />
                    </>
                }
                {tab === ITEM_TAB_NAME ? 
                    id === PAGE_TAB_NAME_LIST ?
                        <PreferenceItemsTab />
                        :
                        <PreferenceItemEdit /> 
                    : null}
                {tab === GROUP_TAB_NAME ? 
                    id === PAGE_TAB_NAME_LIST ?
                        <PreferenceGroupList />
                        :
                        <PreferenceGroupEdit /> 
                    : null}
                {tab === PHRASE_TAB_NAME ? 
                    <PreferencePhrasesTab />
                    :
                    null}
                {tab === MEAL_TAB_NAME ? 
                    <PreferenceMealTab />
                    : 
                    null}
                {toastMessage ? 
                    <NotificationToast message={toastMessage} link={'/preference/item/list'} type={notificationType} />
                    :
                    null}
            </section>
        );
    } catch (err) {
        console.log(err);
        return <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>;   
    }
};

export default PreferencePage;
