import React from 'react';
import SelectHandler from '../sharedComponents/SelectHandler';
import { i18n } from '../../i18n';
import { preferenceUpdateCerealMixService } from '../../services/preferenceUpdateCerealMixService';
import {PREFERENCE_TYPE_CEREAL_MIX, PREFERENCE_TYPE_COMBINATION, PREFERENCE_TYPES} from "../../constant";

const createListOfOptions = () => {
    return PREFERENCE_TYPES.map(it => {
        return {
            id: it,
            name: i18n.get(`preference.scheme.preferenceType.${it}`),
        };
    });
};

const findPreferenceTitle = (itemSaveService, index, parentMode) => {
    if (parentMode === true) {
        if (itemSaveService.getPreference().preferenceType) {
            return i18n.get(`preference.scheme.preferenceType.${itemSaveService.getPreference().preferenceType}`);
        } else {
            return '';
        }
    } else {
        if (index === undefined || !itemSaveService.getPreference().combinedPreferences[index].preferenceType) {
            return '';
        } else {
            return i18n.get(`preference.scheme.preferenceType.${itemSaveService.getPreference().combinedPreferences[index].preferenceType}`);
        }
    }
};

const PreferenceStructureType = ({ itemSaveService, index, parentMode}) => {
    const updatePreferenceValue = (value) => {
        if (value === PREFERENCE_TYPE_COMBINATION) {
            itemSaveService.updatePreferenceProperty('preferenceType', value);
        } else {
            if(parentMode) {
                itemSaveService.updatePreferenceProperty('preferenceType', value);
                if (value === PREFERENCE_TYPE_CEREAL_MIX) {
                    preferenceUpdateCerealMixService.changeIngredientType(value);
                }
            } else {
                itemSaveService.updateCombinedPreference(index, 'preferenceType', value);
            }
        }
    };

    return (
        <label className="block__text preference__form-label">
            <span>{i18n.get('app.page.preference.addTitles.preferenceType')}</span>
            <SelectHandler 
                selectedValue={findPreferenceTitle(itemSaveService, index, parentMode)}
                optionsList={createListOfOptions()} 
                sendSelectedValue={updatePreferenceValue}
            /> 
        </label> 
    );
};

export default PreferenceStructureType;
